export default {
    "header":{
        "gameRecharge":"遊戲儲值",
        "hello":"您好",
        "title":"儲值平臺",
        "saveDesktopTitle":"Journey Renewed 支付中心"
    },
    "btn":{
        "login":"登入",
        "confirm":"確定",
        "cancel":"取消",
        "clear":"清空",
        "pay":"結算",
        "payNow":"立即付款",
        "gotIt":"知道了",
        "saveDesktop":"保存桌面",
        "saveDesktopMobile":"保存桌面",
        "delete": "删除"
    },
    "form":{
        "label":{
            "region":"選擇地區",
            "lang":"選擇語言"
        },
        "placeholder":{
            "select":"請選擇{name}"
        }
    },
    "warn":{
        "networkError":"網路錯誤，檢查網路",
        "timeoutError":"請求超時，請稍後使用",
        "serviceError":"服務端異常，請稍後再試",
        "requestError":"請求異常",
        "authFail":"登入超時",
        "authVerifyFail":"登入資訊校驗失敗",
        "loginError":"登入異常",
        "loginErrorVerify":"登入失敗，請您先進入遊戲解鎖",
        "tips":"提示",
        "noData":"暫無數據",
        "paySuccessMsg":"交易付款成功，正在跳轉至商戶頁面",
        "payLoadingMsg":"订单結果查詢中…",
        "copySuccess":"複製成功",
        "serverEmpty":"請選擇伺服器",
        "roleEmpty":"請選擇角色",
        "loading":"加載中...",
        "noMore":"沒有更多了",
        "loginTips":"請登入需要儲值的遊戲帳號",
        "recordTips":"僅顯示查詢1個月~6個月內的儲值記錄",
        "pleaseSelectPayMethod":"請選擇付款選項",
        "payAmountExceedLimit":"無可用付款方式：單筆付款總金額超過限制，請更改購買數量或選擇其他檔位",
        "payWaiting":"訂單付款中，請稍後",
        "orderCancel":"訂單取消後將不再發貨，已享受的優惠折扣將返還。請確認您未付款並取消該筆訂單？",
        "paying":"若已完成付款，請稍後在遊戲內查收商品。",
        "noFindRole":"未找到角色？試試切換帳號>",
        "beIntercepted":"瀏覽器有攔截，請先取消攔截",
        "maintaining":"您好~系統維護中，預計將於{date}結束，請稍後"
    },
    "account":{
        "switchAccount":"切換帳號",
        "help":"幫助中心",
        "rechargeRecord":"交易記錄",
        "welcome":"歡迎來到儲值中心",
        "pleaseSelectRole":"請選擇角色",
        "latestLogin":"最近登入",
        "changeRole":"更換角色",
        "loginAccount":"登入帳號",
        "selectRole":"選擇區服角色",
        "startReachrge":"開始儲值",
        "server":"伺服器",
        "roleID":"角色ID",
        "roleName":"角色名稱",
        "country":"國家/地區",
        "empty":"空的",
        "noRoles":"您的帳號{name}下暫時沒有角色，請先進入遊戲創建角色，或更換其他帳號登入",
        "roleInfo":"角色資訊",
        "popRole": {
            "cancel": "取消"
        }
    },
    "mall":{
        "title":{
            "buy":"購買商品",
            "payCart":"確認訂單",
            "payType":"支付方式"
        },
        "type":{
            "card":"信用卡/儲蓄卡",
            "bankApp":"手機銀行",
            "bankTransfer":"銀行轉帳",
            "onlineBank":"網上銀行"
        },
        "otherCard":"其他帳號",
        "totalPrize":"合計",
        "date":"日期時間",
        "dateRange":"查詢日期",
        "dateBegin":"開始時間",
        "dateEnd":"結束時間",
        "rangeSeparator":"至",
        "productName":"商品名稱",
        "orderAmount":"購買金額",
        "orderNumber":"訂單號",
        "orderStatus":"訂單狀態",
        "status":{
            "unpaid":"未付款",
            "paySuccess":"付款成功",
            "payFail":"支付失敗",
            "finish":"訂單完成",
            "invalid":"失效"
        },
        "orderConfirmBox": {
            "confirm":"確定",
            "cancel":"取消"
        },
        "cancelOrder":"取消訂單",
        "recommend":"最近使用",
        "buyContinue":"繼續購買",
        "goodsTitle":"儲值選項",
        "goodsPrizeOriginal":"商品原價",
        "goodsAdd":"商品加贈/減免",
        "buySuccess":"購買成功",
        "buySuccessMsg":"商品已發送，請在遊戲內查收",
        "deleteCardTips": "您確認要删除綁定的信用卡/儲蓄卡資訊",
        "orderOneCancel": {
            "title": "未支付訂單提醒",
            "content": "以下未支付訂單可能佔用了您的充值優惠，取消訂單可返回優惠資格",
            "btnCancel": "忽略",
            "btnConfirm": "一鍵取消"
        }
    },
    "pop":{
        "stepOne":"第一步：",
        "stepOneText":"點擊瀏覽器工具",
        "stepTwo":"第二步：",
        "stepTwoText":"在彈出框中點擊“添加到主屏幕”",
        "bookmarkPage":"收藏網頁",
        "bookmarkPageContent":"在導航欄<span class=\"key_words\">點擊收藏圖標</span> 或 <span class=\"key_words\">Ctrl+D</span>,即可收藏網頁",
        "saveDesktopAndroid":"請點擊瀏覽器更多功能，選擇“添加桌面”，或者收藏功能☆保存網頁"
    }
}