import i18n from '@/i18n';
import { Message, MessageBox } from 'element-ui';
import { Dialog, Toast } from 'vant';
import { isMobile } from './utils';

export const tipsSuccess = (msg) => {
    if(isMobile()) {
        Toast.success(msg)
    } else {
        Message.success(msg)
    }
}
export const tipsError = (msg) => {
    Toast(msg)
}
export const tipsWarning = (msg) => {
    if(isMobile()) {
        Toast(msg)
    } else {
        Message.warning(msg)
    }
}
export const tips = (message) => {
    Toast({
        message,
        duration: 4000
    })
}


export const comfirmBox = (options, success, fail) => {
    
    if(typeof options === 'string') {
        options = {
            msg: options
        }
    }

    if (isMobile() || options.useMobile) {
        Dialog.alert({
            title: options.title || i18n.t('sys.warn.tips'),
            message: options.msg,
            showCancelButton: true,
            confirmButtonText: options.confirmText,
            cancelButtonText: options.cancelText
        }).then(() => {
            success && success()
        }).catch(() => {
            fail && fail()
        });
    } else {
        MessageBox({
            title: i18n.t('sys.warn.tips'),
            message: options.msg,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: options.confirmText,
            cancelButtonText: options.cancelText
        }).then(() => {
            success && success()
        }).catch(() => {
            fail && fail()
        })
    }

}
