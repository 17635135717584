export default {
  cn: {
    payment: '支付方式',
    currency: '币种',
    item: '商品',
    total: '合计',
    payNow: '立即支付',
    latest: '上次使用',
    paySuccess: '支付成功',
    payFailed: '支付失败',
    waitPayResult: '等待支付结果',
    timeoutPayResult: '获取支付结果超时',
    tryAgain: '重试',
    pleaseSelectPayMethod: '请选择支付方式',
    networkError: '网络异常',
    close: '关闭',
    delete: '删除',
    delConfirm: '您确认要删除绑定的信用卡/储蓄卡信息',
    confirm: '确认',
    cancel: '取消'
  },
  en: {
    payment: 'Payment Methods',
    currency: 'Currency',
    item: 'Item Name',
    total: 'Total',
    payNow: 'Pay Now',
    latest: 'Last Used',
    paySuccess: 'Payment successful',
    payFailed: 'Payment failed',
    waitPayResult: 'Payment results loading',
    timeoutPayResult: 'Payment results retrieval timed out',
    tryAgain: 'Try again',
    pleaseSelectPayMethod: 'Please select payment method',
    networkError: 'Network Error',
    close: 'Close',
    delete: 'Delete',
    delConfirm: 'Are you sure you want to delete your linked credit/debit card information?',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  id: {
    payment: 'Metode Pembayaran',
    currency: 'Mata Uang',
    item: 'Nama Item',
    total: 'Total',
    payNow: 'Bayar Sekarang',
    latest: 'Terakhir Gunakan',
    paySuccess: 'Pembayaran Berhasil',
    payFailed: 'Pembayaran Gagal',
    waitPayResult: 'Menunggu hasil pembayaran',
    timeoutPayResult: 'Pengambilan hasil pembayaran telah melewati batas waktu',
    tryAgain: 'Coba Lagi',
    pleaseSelectPayMethod: 'Silakan pilih metode pembayaran',
    networkError: 'Jaringan bermasalah',
    close: 'Tutup',
    delete: 'Hapus',
    delConfirm: 'Yakin akan menghapus info kartu kredit/debit yang terikat?',
    confirm: 'Oke',
    cancel: 'Batalkan'
  },
  th: {
    payment: 'วิธีการชำระเงิน',
    currency: 'สกุลเงิน',
    item: 'ชื่อผลิตภัณฑ์',
    total: 'ยอดรวม',
    payNow: 'ชำระทันที',
    latest: 'ใช้ล่าสุด',
    paySuccess: 'ชำระเงินสำเร็จ',
    payFailed: 'ชำระเงินล้มเหลว',
    waitPayResult: 'รอผลการชำระเงิน',
    timeoutPayResult: 'รับผลการชำระเงินหมดเวลา',
    tryAgain: 'ลองอีกครั้ง',
    pleaseSelectPayMethod: 'กรุณาเลือกช่องทางการชำระเงิน',
    networkError: 'เครือข่ายผิดปกติ',
    close: 'ปิด',
    delete: 'ลบ',
    delConfirm: 'คุณยืนยันว่าต้องการลบข้อมูลบัตรเครดิต/บัตรเดบิตที่ถูกผูกไว้หรือไม่?',
    confirm: 'ยืนยัน',
    cancel: 'ยกเลิก'
  },
  vi: {
    payment: 'Phương thức thanh toán',
    currency: 'Loại tiền tệ',
    item: 'Tên vật phẩm',
    total: 'Tổng cộng',
    payNow: 'Thanh toán ngay',
    latest: 'Lần sử dụng cuối',
    paySuccess: 'Thanh toán thành công',
    payFailed: 'Thanh toán thất bại',
    waitPayResult: 'Đang chờ kết quả thanh toán',
    timeoutPayResult: 'Thời gian chờ kết quả thanh toán đã hết',
    tryAgain: 'Thử lại',
    pleaseSelectPayMethod: 'Vui lòng chọn kênh thanh toán',
    networkError: 'Lỗi mạng',
    close: 'Đóng',
    delete: 'Xóa',
    delConfirm: 'Bạn có chắc chắn muốn xóa thông tin thẻ tín dụng/thẻ ghi nợ đã liên kết không? ',
    confirm: 'Xác nhận',
    cancel: 'Hủy'
  }
}