export default {
    "game":{
        "name":"排球少年"
    },
    "user":{
        "helpContent":"<p>支付中心说明</p><p></p><p>1. 本服务仅对已绑定 Google、Apple 和 Facebook 的用户开放。</p><p></p><p>2. 网页端购买的物品将直接发放至您的游戏账号，并可以在游戏中直接使用，请各位玩家注意查收。</p><p></p><p>3. 支付前请仔细核对您的账户详情。如果账户信息不符，请重新更换角色，切换至正确的账号角色后，进行下一步付款。</p><p></p><p>4. 付款成功后，如果您在付款成功后 30 分钟内仍未收到奖品，请联系游戏内客服人员。</p>"
    },
    "recharge":{
        "instructions":"<p>チャージ説明</p><p></p><p>1.お支払いを行う前に、サーバーとキャラクター情報をご確認ください。チャージを完了した後、購入されたアイテムはゲーム内メールにて配布されますので、そちらからアイテムをお受け取りください。</p><p></p><p>2.ブラウザでのチャージには、アイテムが遅れて反映される場合がございます。長時間が過ぎてもアイテムが反映されない場合は、再度ログインするか、カスタマーサービスまでお問い合わせください。</p><p></p><p>3.公式チャージセンターでは、お得なキャンペーンが不定期開催中です。公式お知らせからキャンペーン開催の最新情報をご確認いただけます。</p><p></p><p>お問い合わせ先</p><p>メールでのお問い合わせ：HKFH_SUPPORT@cyou-inc.com</p>"
    },
    "footer":{
        "termsOfService":"利用规则",
        "privacyPolicy":"资金决法",
        "officialFanGroup":"特定商取引法",
        "serviceCenter":"服务中心"
    }
}