export default {
    "game":{
        "name":"ハイキュー!!FLY HIGH"
    },
    "user":{
        "helpContent":"<p>チャージセンター説明</p><p></p><p>1.本サービスはApple ID／Facebook／Googleアカウントと連携済みのアカウントのみが利用可能です。</p><p></p><p>2.チャージセンターで購入されたアイテムは直接ゲームアカウントに送られ、ゲーム内で使用することができます。ゲーム内にてお受け取りください。</p><p></p><p>3.お支払いを行う前に、アカウント情報をご確認ください。アカウント情報に間違いがございましたら、ユーザー変更などを行い、正しいアカウント＆ユーザー情報をご確認の上お支払いにお進みください。</p><p></p><p>4.お支払いが成功してから30分が過ぎてもアイテムが反映されない場合は、ゲーム内にてカスタマーサービスまでお問い合わせください。</p>"
    },
    "recharge":{
        "instructions":"<p>チャージ説明</p><p></p><p>1.お支払いを行う前に、サーバーとキャラクター情報をご確認ください。チャージを完了した後、購入されたアイテムはゲーム内メールにて配布されますので、そちらからアイテムをお受け取りください。</p><p></p><p>2.ブラウザでのチャージには、アイテムが遅れて反映される場合がございます。長時間が過ぎてもアイテムが反映されない場合は、再度ログインするか、カスタマーサービスまでお問い合わせください。</p><p></p><p>3.公式チャージセンターでは、お得なキャンペーンが不定期開催中です。公式お知らせからキャンペーン開催の最新情報をご確認いただけます。</p><p></p><p>お問い合わせ先</p><p>メールでのお問い合わせ：HKFH_SUPPORT@cyou-inc.com</p>"
    },
    "footer":{
        "termsOfService":"利用規約&プライバシ一ポリシ一",
        "privacyPolicy":"資金決済法に基づく表示",
        "officialFanGroup":"特定商取引法に基づく表示",
        "serviceCenter":"カスタマーサービス"
    }
}