export default {
    "header":{
        "gameRecharge":"Top-Up Game",
        "hello":"Halo",
        "title":"Platform Top-Up",
        "saveDesktopTitle":"Pusat Pembayaran Reinkarnasi Perjalanan"
    },
    "btn":{
        "login":"Login",
        "confirm":"OK",
        "cancel":"Batalkan",
        "clear":"Kosongkan",
        "pay":"Perhitungan",
        "payNow":"Bayar Sekarang",
        "gotIt":"Sudah mengerti",
        "saveDesktop":"Simpan Layar",
        "saveDesktopMobile":"Simpan Layar",
        "delete": "Hapus"
    },
    "form":{
        "label":{
            "region":"Pilih Region",
            "lang":"Pilih Bahasa"
        },
        "placeholder":{
            "select":"Silakan Pilih{name}"
        }
    },
    "warn":{
        "networkError":"Kesalahan jaringan, periksa jaringan",
        "timeoutError":"Permintaan mencapai batas waktu, silakan coba lagi nanti",
        "serviceError":"Terjadi kesalahan server, silakan coba lagi nanti",
        "requestError":"Permintaan abnormal",
        "authFail":"Login mencapai batas waktu",
        "authVerifyFail":"Verifikasi info login gagal",
        "loginError":"Login abnormal",
        "loginErrorVerify":"Gagal login, silakan masuk ke game untuk aktivasi terlebih dahulu",
        "tips":"Tips",
        "noData":"Tidak ada data",
        "paySuccessMsg":"Pembayaran sukses, sedang dialihkan ke halaman Merchant",
        "payLoadingMsg":"Sedang mengecek status pesanan...",
        "copySuccess":"Salin berhasil",
        "serverEmpty":"Silakan pilih server",
        "roleEmpty":"Silakan Pilih Karakter",
        "loading":"Loading...",
        "noMore":"Tidak ada lagi",
        "loginTips":"Silakan login ke akun game yang akan di Top-Up",
        "recordTips":"Hanya menampilkan Histori Top Up dalam 6 bulan terakhir",
        "pleaseSelectPayMethod":"Silakan pilih metode pembayaran",
        "payAmountExceedLimit":"Tidak ada metode pembayaran yang tersedia: Jumlah total pembayaran per transaksi melebihi batas, silakan kurangi jumlah pembelian atau pilih nominal lain",
        "payWaiting":"Pesanan sedang dibayar, mohon ditunggu.",
        "orderCancel":"Setelah pesanan dibatalkan, item tidak akan dikirimkan, dan diskon akan dikembalikan. Pastikan Anda belum melakukan pembayaran dan akan membatalkan pesanan ini?",
        "paying":"Bila pembayaran sudah selesai, silakan cek item di dalam game.",
        "noFindRole":"Belum menemukan karakter? Coba tukar akun >",
        "beIntercepted":"Browser dalam pemblokiran, silakan batalkan pemblokiran terlebih dahulu",
        "maintaining":"Sedang maintenance, diperkirakan akan dibuka pada {date}, mohon ditunggu"
    },
    "account":{
        "switchAccount":"Ganti Akun",
        "help":"Pusat Bantuan",
        "rechargeRecord":"Histori Transaksi",
        "welcome":"Selamat Datang di Pusat Pembayaran",
        "pleaseSelectRole":"Silakan Pilih Karakter",
        "latestLogin":"Login Terakhir",
        "changeRole":"Ganti Karakter",
        "loginAccount":"Login Akun",
        "selectRole":"Pilih Server Karakter",
        "startReachrge":"Mulai Top-Up",
        "server":"Server",
        "roleID":"ID Karakter",
        "roleName":"Nama Karakter",
        "country":"Negara/Region",
        "empty":"kosong",
        "noRoles":"Akun {name} tidak temukan. Silakan login ke dalam game untuk membuat karakter, atau gunakan akun login lainnya.",
        "roleInfo":"Info Karakter",
        "popRole": {
            "cancel": "Batalkan"
        }
    },
    "mall":{
        "title":{
            "buy":"Pembelian Item",
            "payCart":"Konfirmasi Pesanan",
            "payType":"Metode Pembayaran"
        },
        "type":{
            "card":"Kartu Kredit/Kartu Debit",
            "bankApp":"Mobile Banking",
            "bankTransfer":"Bank Transfer",
            "onlineBank":"Online Banking"
        },
        "otherCard":"Akun Lainnya",
        "totalPrize":"Total",
        "date":"Tanggal Waktu",
        "dateRange":"Tanggal Pencarian",
        "dateBegin":"Waktu Mulai",
        "dateEnd":"Waktu Berakhir",
        "rangeSeparator":"hingga",
        "productName":"Nama Item",
        "orderAmount":"Nominal Pembelian",
        "orderNumber":"Nomor Pesanan Transaksi",
        "orderStatus":"Status Pesanan",
        "status":{
            "unpaid":"Belum Dibayar",
            "paySuccess":"Pembayaran Berhasil",
            "payFail":"Pembayaran Gagal",
            "finish":"Pesanan Selesai",
            "invalid":"Invalid"
        },
        "orderConfirmBox": {
            "confirm":"OK",
            "cancel":"Batalkan"
        },
        "cancelOrder":"Batalkan Pesanan",
        "recommend":"Baru-baru ini digunakan",
        "buyContinue":"Lanjutkan Pembelian",
        "goodsTitle":"Pilihan Top-Up",
        "goodsPrizeOriginal":"Harga Awal Item",
        "goodsAdd":"Bonus/Diskon Item",
        "buySuccess":"Pembelian berhasil",
        "buySuccessMsg":"Item sudah dikirim, silakan cek item di dalam game",
        "deleteCardTips": "Yakin akan menghapus info kartu kredit/debit yang terikat?",
        "orderOneCancel": {
            "title": "Peringatan pesanan belum dibayar",
            "content": "Berikut adalah pesanan yang masih belum dibayar dan mungkin telah menggunakan diskon Top Up Anda. Membatalkan pesanan dapat mengembalikan kualifikasi diskon",
            "btnCancel": "Abaikan",
            "btnConfirm": "Batalkan Semua"
        }
    },
    "pop":{
        "stepOne":"Langkah Pertama:",
        "stepOneText":"Ketuk aplikasi browser",
        "stepTwo":"Langkah Kedua:",
        "stepTwoText":"Ketuk \"Tambahkan ke Layar Utama\" pada jendela Pop-up",
        "bookmarkPage":"Bookmark Halaman Web",
        "bookmarkPageContent":"Di bilah navigasi, <span class=\"key_words\">ketuk ikon bookmark</span> atau <span class=\"key_words\">Ctrl+D</span> untuk Bookmark Halaman Web",
        "saveDesktopAndroid":"Silakan ketuk fitur lainnya pada browser, pilih \"Tambahkan ke Layar\", atau fitur Bookmark<span class='icon icon-star'></span>untuk menyimpan halaman web"
    }
}